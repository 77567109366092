<template>
  <div class="pay-layout-container">
    <div
      v-if="!isLoading"
      class="container-fluid size pr-0 style-top-container"
    >
      <h1 class="py-3 text-center">
        Su pago ha sigo realizado con éxito. <br />
        Su código de confirmación es {{ this.getCurrentInvoice.transaction_id }}
      </h1>
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <div
            class="col-md-6-cart table-shopping-cart container-buy__checkout pr-3"
          >
            <div class="buy">
              <h2>RESUMEN</h2>
            </div>
            <div class="container-instructors pt-2">
              <div
                v-for="order in this.getCurrentInvoice.orders"
                :key="order.course.id"
                class="row justify-content-between py-3 mx-0 border-top-checkout"
              >
                <div class="col-9 d-flex align-items-center pl-0">
                  <img
                    class="rounded"
                    :src="
                      order.course.thumbnail_url
                        ? order.course.thumbnail_url
                        : 'https://ricamente.net/wp-content/uploads/2021/02/Maquillar.jpeg'
                    "
                    width="80"
                    alt=""
                  />
                  <p class="mb-0 pl-3">{{ order.course.name }}</p>
                </div>
                <div class="col-3 pr-0 text-right course-price">
                  <div v-if="order.course.discount" class="mb-0">
                    <s>${{ order.course.price.toFixed(2) }} USD</s>
                  </div>
                  <div v-else class="mb-0">
                    ${{ order.course.price.toFixed(2) }} USD
                  </div>
                  <div v-if="order.course.discount" class="mb-0">
                    -{{ order.course.discount }}%
                  </div>
                  <div v-if="order.course.discount" class="mb-0">
                    ${{
                      parseFloat(
                        order.course.priceWithDiscount.toString(),
                      ).toFixed(2)
                    }}
                  </div>
                </div>
              </div>

              <div class="row justify-content-between">
                <hr class="w-100 mx-3 my-0 pt-3" />
                <div class="col-5">
                  <p>Subtotal</p>
                </div>
                <div class="col-5 d-flex justify-content-end">
                  <p>${{ getTotal(this.getCurrentInvoice.orders) }} USD</p>
                </div>
                <div v-if="this.getCurrentInvoice.coupon" class="col-5">
                  <p>descuento</p>
                </div>
                <div
                  v-if="this.getCurrentInvoice.coupon"
                  class="col-5 d-flex justify-content-end"
                >
                  <p>
                    -${{
                      parseInt(
                        this.getCouponDiscount(this.getCurrentInvoice.orders),
                      )
                    }} USD
                  </p>
                </div>
                <hr class="w-100 mx-3 my-2" />
                <div class="col-5">
                  <p>Total Pago</p>
                </div>
                <div class="col-5 d-flex justify-content-end">
                  <p class="total">
                    ${{ getTotal(this.getCurrentInvoice.orders) }} USD
                  </p>
                </div>
                <div v-if="this.getCurrentInvoice.coupon" class="col-5">
                  <p>Total con descuento</p>
                </div>
                <div
                  v-if="this.getCurrentInvoice.coupon"
                  class="col-5 d-flex justify-content-end"
                >
                  <p>
                    ${{
                      this.getCurrentInvoice.profit + this.getCurrentInvoice.fee
                    }} USD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6 offset-sm-3 mt-3 text-center">
          <router-link to="/user/cursos">
            <button
              class="btn base-button cancel-button-outline btn-primary btn-maquillate py-2 px-3"
            >
              VER MIS CURSOS
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <loading-panel v-else></loading-panel>
  </div>
</template>

<script>
import { FETCH_INVOICE } from '../../../store/actions/invoice';
import LoadingPanel from '../../../components/LoadingPanel';
import { mapGetters } from 'vuex';

export default {
  name: 'CompletePayment',
  components: {
    LoadingPanel,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(['getCurrentInvoice', 'getCoursesInvoice']),
  },
  watch: {
    getCurrentInvoice: function (newValues) {
      if (newValues && newValues.id) {
        const total = this.getTotal(newValues.orders);
        const script = document.createElement('script');
        script.innerHTML = `fbq('track', 'Purchase', {currency: "USD", value: ${total.toFixed(
          2,
        )}});`;
        document.body.appendChild(script);
      }
    },
  },
  async mounted() {
    await this.fetchInvoice();
  },
  methods: {
    getCouponDiscount(orders = []) {
      const discount = orders.reduce((acc, curr) => {
        return curr.course.discount
          ? acc + curr.course.price * (curr.course.discount / 100)
          : acc;
      }, 0);
      return parseFloat(discount.toString()).toFixed(2);
    },
    getTotal(orders = []) {
      return orders.reduce((acc, currentOrder) => {
        if(currentOrder.course){
          return acc + currentOrder.course.price;

        }
        return acc;
      }, 0);
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async fetchInvoice() {
      const invoiceId = this.$route.params.invoiceId;
      try {
        await this.$store.dispatch(FETCH_INVOICE, invoiceId);
      } catch {
        this.notify('danger', 'El numero de factura no existe');
        this.$router
          .push({ name: 'Home' })
          .then()
          .catch((error) => console.log('route', error.message));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.course-price {
  font-size: 14px;
}

.pay-layout-container {
  padding: 80px 0px;
  background-color: #fff;
}
</style>
